<template>
  <div class="public_css_module personal_right_box teacher_studio">

    <div class="personal_top_header">
      <div class="leftResearch">
        <div class="page_title">我的四名工作室</div>
        <div v-for="(item, index) in selectData" :key="index" :class="currentNum == item.id ? 'active' : 'noClass'" @click="showData(item)">
          <span>{{ item.name }}</span>
        </div>
      </div>
      <!-- <router-link to="/newActivity" class="rightLink">
        <img src="@/assets/imgFIle_4/add.png" />
        <span>创建活动</span>
      </router-link> -->
    </div>

    <div class="list_box" v-loading="listLoading">

      <!-- <ListTwo v-if="total" :list="studioList"></ListTwo> -->

      <div class="item_1_item" v-for="(item, index) in studioList" :key="index" @click.stop="toDetailPage(item.id)">
        <div class="item_1_top fxaic curp">
          <div class="item_1_img">
            <img :src="item.photo_url" alt="" />
            <!-- <div v-if="item.audit_status == 1" class="tios_div_box_1_div tios_div_box_4_div">未通过</div>
            <div v-if="item.audit_status == 2" class="tios_div_box_1_div tios_div_box_2_div">审核中</div>
            <div v-if="item.audit_status == 3" class="tios_div_box_1_div tios_div_box_6_div">已过期</div>
            <div v-if="item.audit_status == 4" class="tios_div_box_1_div tios_div_box_3_div">进行中</div>
            <div v-if="item.audit_status == 5" class="tios_div_box_1_div tios_div_box_5_div">未开始</div>
            <div v-if="item.audit_status == 6" class="tios_div_box_1_div tios_div_box_7_div">已结束</div> -->

            <div v-if="item.audit_status == 1" class="tios_div_box_1_div tios_div_box_2_div">待审核</div>
            <div v-if="item.audit_status == 4" class="tios_div_box_1_div tios_div_box_3_div">已通过</div>
            <div v-if="item.audit_status == 5" class="tios_div_box_1_div tios_div_box_4_div">未通过</div>
          </div>
          <div class="item_1_msg fx1">
            <div class="title_1_div">
              <template v-if="false">
                <span v-if="item.audit_status == 1" class="status_1_box zhuantai_1">审核中</span>
                <span v-if="item.audit_status == 4" class="status_1_box zhuantai_1">已通过</span>
                <span v-if="item.audit_status == 5" class="status_1_box zhuantai_2">未通过</span>
                <span v-if="item.audit_status == 6" class="status_1_box zhuantai_2">已过期</span>
              </template>
              <span class="name_1_div ellipsis_1">{{item.teaching_studio_name || "-"}}</span>
            </div>
            <div class="tag_1_box fxaic">
              <template v-if="item.user_activity">
                <div v-if="item.user_activity == 1" class="tag_item tag_2_item">我创建的</div>
                <div v-if="item.user_activity == 2" class="tag_item tag_3_item">我参与的</div>
              </template>
              <template v-else-if="item.my_type_text">
                <div v-if="item.my_type_text=='我创建的'" class="tag_item tag_2_item">我创建的</div>
                <div v-if="item.my_type_text=='我参与的'" class="tag_item tag_3_item">我参与的</div>
              </template>
              <!-- <div class="tag_item">{{ item.type_text || "-" }}</div> -->
              <div class="tag_item tag_1_item">{{ item.stage_name || "-" }}{{ item.subject_name || "-" }}</div>
            </div>
            <div class="name_tab_box">
              <div class="name_box fxaic">
                <div class="name_name">成员: {{ item.user_count || "0" }}</div>
                <div class="name_name">访问: {{ item.view_count || "0" }}</div>
                <!-- <div class="name_name">活跃度: {{ item.activity_count || "0" }}</div> -->
              </div>
              <div class="name_box fxaic">
                <div class="name_name">活跃度: {{item.activity_count || "0" }} 【<span
                    class="name_1_name">{{item.resource_count || "0" }}</span>个研修资源、<span
                    class="name_2_name">{{item.activity_live_course_count || "0" }}</span>个活动与讲堂】
                </div>
                <el-dropdown pdown trigger="hover" v-if="(item.is_go_examine_role==1) || (item.is_admin==1)">
                  <span class="name_xiala_tag" @click.stop="">
                    <i class="el-icon-more"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-if="item.is_go_examine_role==1" divided @click.native="studio_to_page(item, 1)">
                      <i class="el-icon-position"></i>去审核
                    </el-dropdown-item>
                    <el-dropdown-item v-if="item.is_admin==1" divided @click.native="onManager(item.id)">
                      <i class="el-icon-edit-outline"></i>管理
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </div>
        </div>
        <div v-if="item.activity_data && item.activity_data.length" class="item_1_list">
          <div class="item_1_1_item" v-for="(ite_2, inde_2) in item.activity_data" :key="inde_2">
            <div class="item_1_1_div"> <i class="icon_div el-icon-s-flag"></i> </div>
            <div class="item_1_2_div ellipsis_1">
              <span class="item_1_3_span">{{ite_2.username || "-"}}</span>
              <span class="item_1_5_span">发布了活动 :</span>
              <span class="item_1_4_span">{{ite_2.name || "-"}}</span>
            </div>
          </div>
        </div>

      </div>
      <CommonEmpty v-if="!total"></CommonEmpty>
      <div v-show="total" class="pagina_div" style="height: 100px;">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="getList.page"
          :page-sizes="[10,20,30,40,50]" :page-size="getList.limit" layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { StudioList } from "@/api/teacherStudio";
import ListTwo from '@/components/CommonList/ListTwo.vue';
import { deepClone } from "@/utils/utils.js";
export default {
  components: { ListTwo },
  data() {
    return {
      listLoading: true, //表格Loading
      selectData: [
        {
          id: 1,
          name: "全部",
        },
        {
          id: 2,
          name: "我创建的",
        },
        {
          id: 3,
          name: "我加入的",
        },
        {
          id: 4,
          name: "待我审核",
        },
      ],
      currentNum: 1,
      total: 0,
      uid: 0,
      studioList: [],
      getList: {
        limit: 10,
        page: 1,
        type: 1,
        user_id: 0,
        status: 0,
      },
    };
  },
  methods: {
    //跳转到编辑页
    onManager(id) {
      localStorage.setItem("studioId", id);
      this.$router.push({
        path: "/AdminHome",
        query: {
          teaching_studio_id: id,
        },
      });
    },
    //跳转到详情
    toDetailPage(id) {
      // localStorage.setItem("studioId", id);
      this.$router.push({
        path: "/TeachLectureIndex",
        query: {
          id: id,
          teaching_studio_id: id,
        },
      });
    },
    studio_to_page(item, num) {
      this.onManager(item.id)
    },

    onGetList() {
      this.listLoading = true;
      try {
        let reqObj = deepClone(this.getList);
        if (reqObj.type == 4) {
          this.$set(reqObj, "status", 1);
          delete reqObj['type'];
        };
        if (reqObj.type == 3) {
          this.$set(reqObj, "status", 3);
          delete reqObj['type'];
        };
        if (reqObj.type == 2) {
          this.$set(reqObj, "status", 2);
          delete reqObj['type'];
        };
        StudioList(reqObj).then((res) => {
          this.studioList = res.data.data;
          this.total = res.data.total;
          this.$nextTick(() => {
            this.listLoading = false;
          });
        });
      } catch (error) {
        this.studioList = [];
        this.total = 0;
        this.$nextTick(() => {
          this.listLoading = false;
        });
      }
    },
    showData(item) {
      this.$set(this.getList, "type", item.id);
      this.$set(this.getList, "page", 1);
      this.$set(this.getList, "status", 0);
      this.currentNum = item.id;
      this.onGetList();
    },
    handleSizeChange(val) {
      this.$set(this.getList, "page", 1);
      this.$set(this.getList, "limit", val);
      this.onGetList();
    },
    handleCurrentChange(val) {
      this.$set(this.getList, "page", val);
      this.onGetList();
    },
  },
  created() {
    let query = this.$route.query;
    if (query.pageType) {
      this.$set(this.getList, "type", Number(query.pageType));
      this.currentNum = Number(query.pageType);
    };
    if (query.statusType) {
      this.$set(this.getList, "audit_status", Number(query.statusType));
    };
    this.$set(this.getList, "user_id", localStorage.getItem("id"));
    this.onGetList();
  },
};
</script>

<style lang="less" scoped>
.teacher_studio {
  width: 100%;
  background-color: #fff;
}
.list_box {
  padding: 0 32px;
  min-height: 200px;
  padding-top: 0;
  padding-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}
.tios_div_box_1_div {
  padding: 0 !important;
  margin: 0 !important;
  position: absolute;
  width: 90px;
  height: 62px;
  top: -20px;
  right: -36px;
  font-size: 12px;
  line-height: 12px;
  font-weight: normal;
  display: flex;
  justify-content: center;
  line-height: 24px;
  align-items: flex-end;
  transform: rotate(45deg);
  background-color: #ff8201;
  color: #fff;
  &.tios_div_box_2_div {
    background-color: #0080ff;
  }
  &.tios_div_box_3_div {
    background-color: #ff8201;
  }
  &.tios_div_box_4_div {
    background-color: #ff0607;
  }
  &.tios_div_box_5_div {
    background-color: #0080ff;
  }
  &.tios_div_box_6_div {
    background-color: #ff0607;
  }
  &.tios_div_box_7_div {
    background-color: #dddddd;
    color: #999;
  }
}
.item_1_item {
  width: 545px;
  min-height: 142px;
  border-radius: 6px;
  padding: 15px 16px;
  box-sizing: border-box;
  margin-right: 18px;
  margin-top: 20px;
  background-color: #f1f6f9;
  border: 1px solid #f1f6f9;
  position: relative;
  &:hover {
    background: rgba(241, 246, 249, 1);
    border: 1px solid rgba(170, 201, 219, 1);
    box-shadow: 0 2px 20px 0 rgba(89, 142, 174, 0.31);
  }
  &:nth-child(even) {
    margin-right: 0;
  }
  &:nth-child(1) {
    margin-top: 10px;
  }
  &:nth-child(2) {
    margin-top: 10px;
  }
  .item_1_img {
    width: 168px;
    height: 112px;
    border-radius: 6px;
    overflow: hidden;
    margin-right: 16px;
    position: relative;
    border: 0.5px solid #eee;
    box-sizing: border-box;
    > img {
      width: 168px;
      height: 112px;
      border-radius: 6px;
    }
    .tios_2_box_div {
      bottom: 0;
      position: absolute;
      text-align: center;
      height: 24px;
      width: 100%;
      background: rgba(0, 0, 0, 0.6);
      font-size: 12px;
      line-height: 24px;
      font-weight: normal;
      color: rgba(255, 255, 255, 1);
    }
  }
  .title_1_div {
    display: flex;
    .status_1_box {
      display: inline-block;
      width: 56px;
      height: 22px;
      border-radius: 4px;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      margin-right: 10px;
      background: #ff8201;
      color: #fff;
      &.zhuantai_1 {
        background-color: #3489ff;
      }
      &.zhuantai_2 {
        background-color: #ff0607;
      }
    }
    .name_1_div {
      flex: 1;
      font-size: 16px;
      line-height: 22px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
    }
  }
  .item_1_msg {
    height: 112px;
    display: flex;
    flex-direction: column;
  }
  .item_1_top {
    width: 100%;
  }
  .item_1_list {
    width: 100%;
    padding-top: 10px;
    .item_1_1_item {
      display: flex;
      align-items: center;
      height: 16px;
      margin-top: 10px;
      .item_1_1_div {
        width: 16px;
        height: 16px;
        background: rgba(52, 137, 255, 1);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon_div {
          color: #fff;
          font-size: 12px;
          transform: scale(0.8);
        }
      }
      .item_1_2_div {
        flex: 1;
        font-size: 14px;
        line-height: 16px;
        font-weight: normal;
        color: #3489ff;
      }
      .item_1_3_span {
        margin-left: 6px;
        color: #333333;
      }
      .item_1_5_span {
        margin-left: 6px;
        color: #999999;
      }
      .item_1_4_span {
        margin-left: 6px;
      }
    }
  }
  .tag_1_box {
    margin-top: 10px;
    .tag_item {
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      height: 24px;
      line-height: 24px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: normal;
      color: rgba(4, 147, 255, 1);
      padding: 0 8px;
      border: 1px solid #0493ff;
      color: #0493ff;
      margin-right: 10px;
    }
    .tag_1_item {
      border: 1px solid #51b875;
      color: #51b875;
    }
    .tag_2_item {
      background-color: #3489fe;
      color: #fff;
    }
    .tag_3_item {
      background-color: #52b876;
      border: 1px solid #51b875;
      color: #fff;
    }
  }
  .name_tab_box {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    .name_box {
      .name_name {
        margin-top: 8px;
        flex: 1;
        font-size: 14px;
        line-height: 14px;
        font-weight: normal;
        color: rgba(153, 153, 153, 1);
        .name_1_name {
          font-weight: bold;
          color: #52b876;
        }
        .name_2_name {
          font-weight: bold;
          color: #fe7019;
        }
      }
      .el-dropdown {
        position: absolute;
        bottom: 0;
        right: 0;
      }
      .name_xiala_tag {
        width: 50px;
        text-align: right;
        color: rgba(153, 153, 153, 1);
        font-size: 14px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>